<template>
  <!-- テンプレート登録ダイアログ -->
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      width="100%"
      max-width="1200px"
    >
      <v-card :loading="postLoading" :disabled="postLoading">
        <v-card-title>
          <span class="text-h5">Xアカウント手動投稿</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="cancelDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="scrollable-content">
          <input type="hidden" v-model="template.id" />
          <ValidationObserver ref="baseObserver">
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="template.content_format_id"
                    name="code"
                    :items="contentFormats"
                    no-data-text="フォーマットを選択して下さい"
                    label="使用フォーマット選択"
                    success
                    outlined
                    hint="使用するフォーマットを選択して下さい[必須項目]"
                    persistent-hint
                    :rules="select_format"
                    @change="onChangeFormat"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </ValidationObserver>
          <!-- フォーマット -->
          <component
            v-bind:is="selectComponent"
            ref="refFormatComponent"
            :template_config="template.config"
            :shop_site_id="props.shop_site_id"
            :shop_site_x_account_id="props.shop_site_x_account_id"
          ></component>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmit"
                  ><template v-slot:icon
                    ><v-icon left>mdi-note-edit</v-icon></template
                  >ポストする</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import retryRequest from "@/common/retryRequest";

export default defineComponent({
  props: ["shop_site_id", "content_format_ids", "shop_site_x_account_id"],
  setup(props, ctx) {
    const contentFormatsRepository = repositoryFactory.get("contentFormats");
    const synchroRepository = repositoryFactory.get("synchro");

    const refFormatComponent = ref();
    const state = reactive({
      dialog: false, // ダイアログ表示用
      template: {
        id: 0,
        name: "",
        content_format_id: 0,
        config: {},
      },
      contentFormats: [],
      baseComponents: {},
      selectComponent: null,
      postLoading: false,
    });

    const clearDialog = async () => {
      // 各種項目初期化
      state.template.id = 0;
      state.template.name = "";
      state.template.content_format_id = 0;
      state.template.config = {};
      state.selectComponent = null;
    };

    const getContentFormatList = async () => {
      // コンテンツフォーマット取得
      const response = await retryRequest(contentFormatsRepository.list);
      if (response.data) {
        const data = [];
        const content_format_ids =
          props.content_format_ids ?? setting.public_template_ids;
        for (let i = 0; i < response.data.length; i++) {
          if (content_format_ids.includes(response.data[i].id)) {
            data.push({
              id: response.data[i].id,
              name: response.data[i].name,
              code: response.data[i].code,
              value: response.data[i].id,
              text: response.data[i].name,
            });
          }
        }
        return data;
      }
    };

    // 新規作成
    const showDialog = async () => {
      await store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // コンテンツフォーマット取得
      state.contentFormats = await getContentFormatList();
      state.template.content_format_id = state.contentFormats[0].id;
      // フォーマット設定
      state.selectComponent =
        state.baseComponents[state.contentFormats[0].code];

      scrollReset();

      // ダイアログ開く
      state.dialog = true;

      // 最初の項目にフォーカスをあわせる
      setTimeout(() => {
        document.querySelector("textarea[name='body']").focus();
      }, 200);

      // ローディング非表示
      await store.dispatch("loadingIcon/hideIcon");
    };

    // ポスト処理
    const onSubmit = async () => {
      // 入力チェック エラー状態の部品が無いか調べる
      const isValid = await ctx.refs.baseObserver.validate();

      if (!isValid) {
        // エラー処理
        const el = document.querySelectorAll(
          ".v-text-field.error--text, .v-textarea.error--text"
        );
        if (el.length > 0) {
          el[0].scrollIntoView();
        }
        return false;
      } else {
        // フォーマットコンポーネント側のチェック処理を行う
        const isComponentValid = await refFormatComponent.value.checkValid();
        if (!isComponentValid) {
          return false;
        }

        if (!window.confirm("ポストしてよろしいですか？")) {
          return false;
        }

        // ローディング表示
        state.postLoading = true;

        // フォーマットコンポーネント側の保存処理を先に行う
        const saveConfig = await refFormatComponent.value.onSubmit();

        // パラメータ取得
        const params = {
          id: state.template.id,
          shop_id: store.getters["shops/currentShop"].id,
          content_format_id: state.template.content_format_id,
          name: state.template.name,
          config: saveConfig,
          is_active: true,
          shop_site_id: props.shop_site_id,
        };

        // ポスト
        const result = await synchroRepository
          .sync_manual_post(params)
          .then((response) => {
            if (response.data) {
              if (response.data.status) {
                return true;
              } else {
                alert(response.data.message);
                return false;
              }
            }
          })
          .catch((error) => {
            throw "ERROR:synchroRepository.sync_manual_post (" + error + ")";
          })
          .finally(() => {
            // ローディング非表示
            state.postLoading = false;

            // 投稿状況の更新
            store.dispatch("x_link/refreshPostInfo");

            // ダイアログを閉じる
            cancelDialog();
          });
      }
    };

    // ダイアログキャンセルボタン
    const cancelDialog = () => {
      state.dialog = false;
      ctx.refs.baseObserver.reset(); // 入力エラー表示クリア
      scrollReset();
      clearDialog();
    };

    const scrollReset = () => {
      setTimeout(() => {
        document.querySelector(".scrollable-content").scroll(0, 0);
      }, 200);
    };

    // フォーマット変更時
    const onChangeFormat = (val) => {
      // コンポーネント（フォーマット）変更
      const format = state.contentFormats.find((v) => v.id == val);
      state.selectComponent = state.baseComponents[format.code];
    };

    const init = () => {
      // onlySiteComponentフォルダのコンポーネントを取得する
      const files = require.context(
        "../../template/component/contentComponent",
        true,
        /\.vue$/
      );
      files.keys().forEach((key) => {
        set(
          state.baseComponents,
          key.replace(/(\.\/|\.vue)/g, ""),
          files(key).default
        );
      });
    };

    init();

    // 返却オブジェクト定義
    return {
      setting,
      props,
      refFormatComponent,
      ...toRefs(state),
      onSubmit,
      cancelDialog,
      showDialog,
      scrollReset,
      onChangeFormat,
      select_format: [(v) => !!v || "必須選択項目です"],
    };
  },
});
</script>

<style scoped>
::v-deep #color-selector .v-input__append-inner {
  margin-top: 13px;
}
.edit_area {
  cursor: pointer;
}
::v-deep .td_content_name {
  width: 160px;
}
::v-deep .td_site_content_name {
  min-width: 160px;
}
</style>
