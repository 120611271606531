<template>
  <div>
    <div>
      <v-app-bar flat color="white">
        <div class="pa-2">
          <general-button
            btn_type="info"
            :btn_loading="linkProcess"
            :btn_disabled="
              linkProcess || post_info.account == post_info.account_limit
            "
            btn_block
            @click-event="xLogin"
            ><template v-slot:icon><v-icon left>mdi-link</v-icon></template
            >アカウント連携</general-button
          >
        </div>
      </v-app-bar>
    </div>
    <div class="text-subtitle-2 pa-1 blue--text blue lighten-5">
      「アカウント連携」ボタンをクリックして、連携したいＸアカウントにログインし、ヤトイテからのアクセス許可を承認してください。
    </div>
    <div class="icon-description-container">
      <div class="text-subtitle-2 pa-1 d-flex nowrap">
        <v-spacer></v-spacer>
        <span class="d-flex align-center">【アイコン】</span>
        <span class="d-flex align-center mr-3"
          ><v-icon class="mr-1" small>mdi-note-edit</v-icon>Xに投稿</span
        >
        <span class="d-flex align-center mr-3"
          ><v-icon class="mr-1" small>mdi-reload</v-icon>プロフィール更新</span
        >
        <span class="d-flex align-center mr-3"
          ><v-icon class="mr-1" small>mdi-delete</v-icon>削除</span
        >
        <span class="d-flex align-center mr-3"
          ><v-icon class="mr-1" small>mdi-drag</v-icon>並べ替え</span
        >
      </div>
    </div>
    <v-card tile :loading="xListLoading" elevation="0">
      <div class="pa-2" v-if="xList.length == 0">
        アカウントが連携されていません。
      </div>
      <draggable
        v-model="xList"
        v-bind="dragOptions"
        handle=".hundle_img"
        :force-fallback="true"
        @end="onDragEnd"
        class="d-flex flex-wrap"
      >
        <div
          v-for="xAccount in xList"
          :key="xAccount.id"
          class="x_card ma-2 responsive-center"
        >
          <v-btn icon class="hundle_img x_drag_btn" color="gray">
            <v-icon>mdi-drag</v-icon>
          </v-btn>
          <v-card
            tile
            elevation="2"
            :loading="xAccount.card_loading"
            :disabled="xAccount.card_loading"
          >
            <v-img
              height="106px"
              :src="
                xAccount.user_background_image_url
                  ? xAccount.user_background_image_url
                  : ''
              "
              v-if="xAccount.user_background_image_url"
            >
            </v-img>
            <v-img
              height="106px"
              src=""
              class="blank_background"
              v-else
            ></v-img>
            <div class="pa-2 overflow-hidden">
              <div class="d-inline-flex align-center">
                <v-avatar size="36px">
                  <img alt="Avatar" :src="xAccount.user_icon_url" />
                </v-avatar>
                <div class="ml-2">
                  <div class="text-truncate">
                    {{ xAccount.user_name }}
                  </div>
                  <div class="mini text-truncate">
                    @{{ xAccount.user_screen_name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="px-2 line-clamp-3 mini">
              {{ xAccount.user_description }}
            </div>
            <v-card-actions>
              <v-btn
                icon
                :disabled="
                  xAccount.api_status != setting.x_api_status_link ||
                  !props.post_info.is_post
                "
                @click="xPost(xAccount.id)"
              >
                <v-icon title="Xに投稿する">mdi-note-edit</v-icon>
              </v-btn>
              <v-btn
                icon
                :disabled="xAccount.api_status != setting.x_api_status_link"
                @click="xUpdate(xAccount.id)"
              >
                <v-icon title="プロフィール更新">mdi-reload</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn icon @click="xDelete(xAccount.id)">
                <v-icon title="連携解除">mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </draggable>
    </v-card>
    <post-dialog-component
      ref="refDialogComponent"
      :shop_site_id="props.com_data.id"
      :content_format_ids="setting.x_com_template_ids"
      :shop_site_x_account_id="editShopSiteXAccountId"
    ></post-dialog-component>
  </div>
</template>

<script>
import { ref, reactive, toRefs, watch, set } from "@vue/composition-api";
import setting from "@/common/setting.js";
import store from "@/store/index.js";
import draggable from "vuedraggable";
import { repositoryFactory } from "@/repository/repositoryFactory";
import retryRequest from "@/common/retryRequest";
import postDialogComponent from "./postDialogComponent.vue";

export default {
  props: ["com_data", "refresh", "post_info"],
  components: {
    draggable,
    postDialogComponent,
  },
  setup(props, ctx) {
    const synchroRepository = repositoryFactory.get("synchro");
    const shopSiteXAccountRepository =
      repositoryFactory.get("shopSiteXAccounts");

    const refDialogComponent = ref();
    const state = reactive({
      linkProcess: false,
      xList: [],
      xListLoading: false,
      editShopSiteXAccountId: 0,
    });

    // 初期表示
    const init = async () => {
      // Xアカウント連携情報取得
      state.xListLoading = true;
      try {
        state.xList.splice(0);
        const params = {
          shop_site_id: props.com_data.id,
        };
        const response = await retryRequest(
          shopSiteXAccountRepository.list_shopsite,
          params
        );
        if (response.data) {
          Object.keys(response.data).forEach(function (key) {
            const items = {
              ...response.data[key],
              card_loading: false,
            };
            state.xList.push(items);
          });
        }
      } catch (error) {
        alert("Xアカウント連携情報の取得に失敗しました。");
      }
      state.xListLoading = false;
    };

    init();

    // props.refresh変更時に再取得（画面リフレッシュ用）
    watch(
      () => props.refresh,
      (value) => {
        init();
      }
    );

    // アカウント連携ボタンクリック
    const xLogin = async () => {
      state.linkProcess = true;

      // URLを取得する
      const params = {
        shop_id: store.getters["shops/currentShop"].id,
        shop_site_id: props.com_data.id,
        shop_site_x_account_id: 0, // 0:新規登録
        site_id: props.com_data.sites_id,
        auth_url: "",
        loginid: "",
        password: "",
      };
      const res = await synchroRepository
        .get_authorization_url(params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          state.linkProcess = false;
          throw (
            "ERROR:synchroRepository.sync_get_authorization_url (" + error + ")"
          );
        });

      if (res.status) {
        // ブラウザでURLを開く
        window.location.href = res.data.auth_url;
      } else {
        alert(res.message);
        state.linkProcess = false;
      }
    };

    const xDelete = async (shop_site_x_account_id) => {
      const index = state.xList.findIndex(
        (x) => x.id === shop_site_x_account_id
      );
      state.xList[index].card_loading = true;

      if (!window.confirm("連携を解除してよろしいですか？")) {
        state.xList[index].card_loading = false;
        return false;
      }
      // 連携を解除する
      const params = {
        shop_id: store.getters["shops/currentShop"].id,
        shop_site_id: state.xList[index].shop_site_id,
        shop_site_x_account_id: shop_site_x_account_id,
      };
      const res = await synchroRepository
        .delete_x_link(params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          state.xList[index].card_loading = false;
          throw "ERROR:synchroRepository.delete_x_link (" + error + ")";
        });

      state.xList[index].card_loading = false;

      ctx.emit("get_post_info");

      // indexを削除
      state.xList.splice(index, 1);
    };

    const xUpdate = async (shop_site_x_account_id) => {
      const index = state.xList.findIndex(
        (x) => x.id === shop_site_x_account_id
      );
      state.xList[index].card_loading = true;

      // プロファイルを更新する
      const params = {
        shop_id: store.getters["shops/currentShop"].id,
        shop_site_id: state.xList[index].shop_site_id,
        shop_site_x_account_id: shop_site_x_account_id,
      };
      const res = await synchroRepository
        .update_x_profile(params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          state.xList[index].card_loading = false;
          throw "ERROR:synchroRepository.update_x_profile (" + error + ")";
        });

      // 媒体情報を更新
      if (res.status) {
        set(
          state.xList[index],
          "user_name",
          res.data.shop_site_x_account.user_name
        );
        set(
          state.xList[index],
          "user_screen_name",
          res.data.shop_site_x_account.user_screen_name
        );
        set(
          state.xList[index],
          "user_icon_url",
          res.data.shop_site_x_account.user_icon_url
        );
        set(
          state.xList[index],
          "user_background_image_url",
          res.data.shop_site_x_account.user_background_image_url
        );
        set(
          state.xList[index],
          "user_description",
          res.data.shop_site_x_account.user_description
        );
      } else {
        alert(res.message);
      }

      state.xList[index].card_loading = false;
    };

    // Xに投稿する。ダイアログ表示
    const xPost = async (shop_site_x_account_id) => {
      state.editShopSiteXAccountId = shop_site_x_account_id;
      await refDialogComponent.value.showDialog();
    };

    const onDragEnd = async () => {
      console.log(
        "onDragEnd",
        state.xList.map((x) => x.id)
      );
      const params = {
        shop_id: store.getters["shops/currentShop"].id,
        shop_site_id: props.com_data.id,
        x_account_ids: state.xList.map((x) => x.id),
      };
      await shopSiteXAccountRepository
        .update_sort(params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw "ERROR:shopSiteXAccountRepository.update_sort (" + error + ")";
        });
    };

    return {
      dragOptions: {
        animation: 200,
        group: "cast",
        disabled: false,
        ghostClass: "ghost",
      },
      setting,
      props,
      refDialogComponent,
      ...toRefs(state),
      xLogin,
      xDelete,
      xUpdate,
      xPost,
      onDragEnd,
    };
  },
};
</script>

<style scoped>
.v-tab--active {
  background-color: #e8f5e9;
}
.blank_background {
  width: 100%;
  background-color: #f5f5f5;
}
.x_card {
  margin-right: 0.25em;
  position: relative;
  width: 318px;
}
.x_drag_btn {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 2;
}
.line-clamp-3 {
  display: -webkit-box; /* Flexboxの代わり */
  -webkit-box-orient: vertical; /* 垂直方向にテキストを並べる */
  overflow: hidden; /* はみ出した部分を非表示にする */
  -webkit-line-clamp: 3; /* 最大表示行数を3行に設定 */
  line-clamp: 3; /* モダンブラウザ向け */
  white-space: normal; /* 折り返しを許可する */
}
@media (max-width: 480px) {
  .responsive-center {
    width: calc(100% - 1em);
  }
}
.icon-description-container {
  overflow-x: auto;
  /* スクロールバーを非表示にする */
  -ms-overflow-style: none; /* IEとEdge */
  scrollbar-width: none; /* Firefox */
}
.nowrap {
  white-space: nowrap;
}
</style>
