<template>
  <div>
    <div class="pa-2 cyan">
      <span class="text-subtitle-1 font-weight-bold white--text"
        >X連携用のコンテンツ設定</span
      >
    </div>
    <v-tabs v-model="refXComTabs" style="border-bottom: 1px solid lightgray">
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="tab in tabs" :key="tab.index" class="font-weight-bold">{{
        tab.name
      }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="refXComTabs" touchless>
      <v-tab-item v-for="tab in tabs" :key="tab.index" :eager="true">
        <component
          v-bind:is="tab.component"
          :refresh="props.com_data.refresh"
          :post_info="props.post_info"
          :com_data="props.com_data"
          :shop_site_id="props.com_data.id"
          :content_format_ids="setting.x_com_template_ids"
          @get_post_info="getPostInfo"
        ></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { ref, reactive, toRefs } from "@vue/composition-api";
import setting from "@/common/setting.js";
import xComComponent from "./component/xCom.vue";
import xTemplateComponent from "../template/index.vue";

export default {
  props: ["com_data", "post_info"],
  components: {
    xComComponent,
    xTemplateComponent,
  },
  setup(props, ctx) {
    const refXComTabs = ref();
    const state = reactive({});

    const tabs = [
      {
        index: 0,
        name: "Xアカウント管理",
        component: xComComponent,
      },
      {
        index: 1,
        name: "X専用テンプレート管理",
        component: xTemplateComponent,
      },
    ];

    const tabMove = (tabIndex) => {
      refXComTabs.value = tabIndex;
    };

    const getPostInfo = () => {
      ctx.emit("get_post_info");
    };

    return {
      setting,
      props,
      refXComTabs,
      tabs,
      ...toRefs(state),
      getPostInfo,
      tabMove,
    };
  },
};
</script>

<style scoped>
.v-tab--active {
  background-color: #e8f5e9;
}
.blank_background {
  width: 100%;
  background-color: #f5f5f5;
}
.x_card {
  margin-right: 0.25em;
  position: relative;
  width: 318px;
}
.x_drag_btn {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 2;
}
.line-clamp-3 {
  display: -webkit-box; /* Flexboxの代わり */
  -webkit-box-orient: vertical; /* 垂直方向にテキストを並べる */
  overflow: hidden; /* はみ出した部分を非表示にする */
  -webkit-line-clamp: 3; /* 最大表示行数を3行に設定 */
  line-clamp: 3; /* モダンブラウザ向け */
  white-space: normal; /* 折り返しを許可する */
}
@media (max-width: 480px) {
  .responsive-center {
    width: calc(100% - 1em);
  }
}
.icon-description-container {
  overflow-x: auto;
  /* スクロールバーを非表示にする */
  -ms-overflow-style: none; /* IEとEdge */
  scrollbar-width: none; /* Firefox */
}
.nowrap {
  white-space: nowrap;
}
</style>
